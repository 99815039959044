




































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { RESET_PASSWORD } from '@/store/user/actions.type'
import { SimpleServerResponse } from '@/store/types'
import { ResetPasswordCredentials } from '@/store/user/types'
import TextCreatorForSubmitSuccess from '@/components/forms/getHeadlineAndInfoForSubmitSuccess'

@Component
export default class ResetPassword extends Vue {
  @Action(RESET_PASSWORD)
  public resetPasswordRequest: (params: ResetPasswordCredentials) => Promise<SimpleServerResponse>

  private emailModel = ''
  private passwordModel = ''
  private id: string | null = null
  private nonce: string | null = null

  private feedbackHeadline = ''
  private responseState = false

  resetPassword (event: Event): void {
    event.preventDefault()
    if (this.id && this.nonce) {
      const params: ResetPasswordCredentials = {
        loginCredentials: {
          username: this.emailModel,
          password: this.passwordModel
        },
        registrationCredentials: {
          id: this.id,
          nonce: this.nonce
        }
      }
      let textCreator: TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>
      this.resetPasswordRequest(params).then(data => {
        textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(data, 'reset-password.')
        this.responseState = true
      }, error => {
        textCreator = new TextCreatorForSubmitSuccess<SimpleServerResponse, keyof SimpleServerResponse>(error, 'reset-password.')
        this.responseState = false
      }).finally(() => {
        this.feedbackHeadline = textCreator.getHeadline()
        this.$root.$emit('alert', this.feedbackHeadline, '', !this.responseState)
      })
    }
  }

  mounted (): void {
    this.id = this.$route.params.id
    this.nonce = this.$route.params.nonce
  }
}
